* {
  box-sizing: border-box;
  color: #777;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  min-height: 100vh;
  background-color: #f2f2f2;
  background-image: url("hexagon.f22d62f4.png");
  background-size: 4.875em 5.5625em;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
}

a {
  border-bottom: 1px dotted;
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid;
}

section {
  padding: 3rem;
}

@media screen and (max-width: 550px) {
  body {
    display: block;
  }

  section {
    padding: 0;
  }
}

.lKb-_a_card {
  box-sizing: border-box;
  background: #ffffff59;
  border: .125rem solid #e2e2e2;
  border-radius: 1.6rem;
  margin: 4rem;
  padding: 2.5rem;
}

.lKb-_a_header {
  width: 100%;
}

.lKb-_a_header a {
  border-bottom: none;
}

.lKb-_a_header img {
  max-width: 100%;
}

.lKb-_a_footer {
  place-self: center stretch;
}

.lKb-_a_footer p {
  margin: 0;
}

.lKb-_a_footer a {
  color: #d941a5;
}

.lKb-_a_footer a:visited {
  color: #1cafbf;
}

.lKb-_a_footer .lKb-_a_rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: sub;
  background: #fff;
  display: inline-block;
}

.lKb-_a_footer .lKb-_a_rsIcon {
  border-bottom: none;
}

@media screen and (max-width: 550px) {
  .lKb-_a_card {
    width: auto;
    min-height: 100vh;
    border: none;
    border-radius: 0;
    margin: 0;
    display: block;
  }

  .lKb-_a_header, .lKb-_a_footer {
    text-align: center;
    margin: 0 auto 2rem;
  }
}

.vRzsIW_main {
  width: 100%;
  max-width: 34rem;
  flex-direction: column;
  gap: 2.5rem;
  margin: 2.5rem auto;
  display: flex;
}

.vRzsIW_welcome {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 300;
}

.vRzsIW_form {
  grid-column-gap: 1rem;
  grid-template-rows: 1.5rem 2.5rem;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.vRzsIW_label {
  grid-column: 1 / -1;
}

.vRzsIW_email {
  height: 2.5rem;
  width: 100%;
  border: .125rem solid #e2e2e2;
  border-radius: .25rem;
  grid-column: 1 / 3;
  padding: 0 .5rem;
}

.vRzsIW_submit {
  color: #fff;
  height: 2.5rem;
  width: 100%;
  background: #1cafbf;
  border: none;
  border-radius: .25rem;
  grid-column: 3 / 4;
}

.vRzsIW_appStores {
  grid-column-gap: 1rem;
  grid-template-rows: 1.5rem 2.5rem;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.vRzsIW_appStores img {
  width: 100%;
  height: 2.5rem;
  display: block;
}

.vRzsIW_apple {
  width: 100%;
  grid-column: 2 / 3;
  place-self: flex-start;
}

.vRzsIW_google {
  width: 100%;
  grid-column: 1 / 2;
  place-self: flex-start;
}

@media screen and (max-width: 550px) {
  .vRzsIW_main, .vRzsIW_form, .vRzsIW_appStores {
    display: block;
  }

  .vRzsIW_welcome, .vRzsIW_form, .vRzsIW_appStores, .vRzsIW_footer {
    text-align: center;
    margin: 0 auto 2rem;
  }

  .vRzsIW_google, .vRzsIW_apple {
    text-align: center;
  }

  .vRzsIW_email, .vRzsIW_google {
    margin-bottom: .5rem;
  }

  .vRzsIW_appStores img {
    width: auto;
    margin: 0 auto;
  }
}

/*# sourceMappingURL=index.67fa32c2.css.map */
