* {
  box-sizing: border-box;
  color: #777;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  align-items: center;
  background-color: #f2f2f2;
  background-image: url('./assets/hexagon.png');
  background-size: 4.875em 5.5625em;
  display: flex;
  font-size: 16px;
  justify-content: center;
  min-height: 100vh;
}

a {
  text-decoration: none;
  border-bottom: 1px dotted;
}

a:hover {
  border-bottom: 1px solid;
}

section {
  padding: 3rem;
}

@media screen and (max-width: 550px) {
  body {
    display: block;
  }

  section {
    padding: 0;
  }
}
